<script>
import VCalendar from 'v-calendar'
import Vue from 'vue'
import modal from '/~/core/mdl'
import { useQrConnect } from '/~/extensions/qr-connect/composables'
import { formatDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useUser } from '/~/composables/user'

Vue.use(VCalendar)

export default {
  name: 'qr-code-scan-dates',
  components: {
    BaseButton,
    BaseMdl,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    startsAt: {
      type: String,
      default: null,
    },
    maxEndsAt: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { user } = useUser()
    const { scanQrCode } = useQrConnect()

    return {
      user,
      scanQrCode,
    }
  },
  data() {
    return {
      endsAt: null,
      loading: false,
    }
  },
  methods: {
    onClose() {
      modal.hide()
    },
    async onConfirm() {
      if (!this.endsAt) {
        return
      }

      this.loading = true
      const [error, response] = await this.scanQrCode({
        qrCode: this.code,
        userExternalId: this.user.externalId,
        endsAt: formatDate('daymonthyearnumeric', this.endsAt),
      })

      this.onClose()
      this.callback(error, response)
      this.loading = false
    },
  },
}
</script>

<template>
  <base-mdl width="xse" title="Select end date">
    <v-date-picker
      v-model="endsAt"
      mode="single"
      class="mt-[30px]"
      color="blue"
      is-expanded
      is-inline
      :min-date="startsAt"
      :max-date="maxEndsAt"
    />
    <base-button
      class="mt-[30px] w-full"
      :disabled="!endsAt"
      :loading="loading"
      @click="onConfirm"
    >
      Confirm
    </base-button>
  </base-mdl>
</template>
